/* About Section Styles */
.about {
    background-color:#1a0020;
    text-align: center;
    width: auto;
    align-content: center;
    padding-bottom: 3rem;

   
  }
  .features{

    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .why{
    color: #f3f2f5;
    font-size: 1.8rem;

    
  }
  .why2{
    font-size: 26px;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #f3f2f5;
  
  }

  .why3{
    font-size: 20px;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #fcfcfc;

    
  }

  .containerAbout {
    max-width: 90%;
   margin: auto;
   padding-bottom: 1rem;
   padding-top: 1rem;
    background-color:  #f15b05;
    border-radius: 1.5rem;
 
    
  
  }
  
  .about-headline {
    font-size: 2.5rem;

  }
  
  .about-subheadline {
    font-size: 1.25rem;
 
  }
  
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .feature {
    background-color: #fc6806;
    border-radius: 8px;
  
    text-align: center;
    width: 100%;
    max-width: 250px;
    color: rgb(255, 255, 255);
  }
  

  
  .feature-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-description {
    font-size: 1rem;
  }










  @media(max-width:768px){

 .about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the section takes at least the full height of the viewport */
  text-align: center; /* Optional: center the text inside the container */
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

    .features{

      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .why{
      color: #f3f2f5;
      font-size: 1.5rem;
  
      
    }
    .why2{
      font-size: 25px;
      font-weight: 100;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      color: #f3f2f5;
      
      
    
    }
  
    .why3{
      font-size: 17px;
      font-weight: 100;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      color: #ffffff;
      margin: 1rem;
  
      
    }
  
    .container2 {
      max-width: 80%;    
     padding-bottom: 1rem;
     padding-top: 1rem;
      background-color:  #ad4001;
      border-radius: 1.5rem;
       }
    
    .about-headline {
      font-size: 2.5rem;
  
    }
    
    .about-subheadline {
      font-size: 1.25rem;
   
    }
    
    .features {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
     
    }
    
    .feature {
      background-color: #fcfcfc;
      border-radius: 8px;
      text-align: center;
      width: 100%;
      max-width: 250px;
      color: rgb(3, 3, 3);
     
    }
    
  
    
    .feature-title {
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    
    .feature-description {
      font-size: .7rem;
      margin: .5rem;
    }

  }
.mainForm {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color:#3a2044;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top:  3rem;
}
.formTitle{
text-align: center;
color: #444049;
}
.form-container {
  display: flex;
  width: 60%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(255, 104, 3, 0.1);
  margin-top: 30px;
}
.imcal{
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.leftTitle{
font-size: 56px;
font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
color: #490335;

}

.leftText{
  font-size: 36px;
font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
color: #0a0a0a;
}

.bysubmit{
  font-size: 12px;
  text-align: center;

}
.formComment,
.formDetail {
  padding: 20px;
  flex: 1;
  background-color: #f9f9f9;
  
 
}
.titleDet {
  font-size: 20px;
  font-family:'Times New Roman', Times, serif;
  color: #ffffff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;

}

.titleDate{
  font-size: 25px;
  font-family:Arial, Helvetica, sans-serif;
  color: #555454;
 
}
.infos{
text-align: center;
align-content: center;

}
.int{
  font-size: 30px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #fcfafa;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  align-items: center;
}
button{
  align-self: center;
  margin-bottom: 1rem;
}

.formComment {
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
}

.formComment h2 {
  margin-bottom: 10px;
}

.formComment p {
  margin: 0;
}
textarea{
  height: 6rem;
}

.formDetail h2 {
  margin-bottom: 20px;
}

.formDetail form {
  display: flex;
  flex-direction: column;
 
}

.formDetail label {
  margin-bottom: 15px;
  font-size: 13px;
  color: #5a5463;
 
}
.instructor{
  display: flex;
  
}



.formDetail input,
.formDetail textarea, select {
  width: 90%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #5a5463;
}

.formDetail button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f35d07;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: 50%;
}

.formDetail button:hover {
  background-color: #ee6f06;
}




/*PHONE AND TABLE*/


@media(max-width:768px) {

  .mainForm {
    display: flex;
  
  
    background-color: #0f0225;

    flex-direction: column;
  }
  .formTitle{
  text-align: center;
  color: #444049;
  }


 
  .form-container {
    display: flex;
    width: 90%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(255, 104, 3, 0.1);
    flex-direction: column;
  }

  .formComment,
  .formDetail {
    padding: 20px;
    flex: 1;
    background-color: #f9f9f9;
    
   
  }

  .formhead{
    text-align: center;
  }
  .imcal{
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .leftTitle{
  font-size: 30px;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #490335;
  
  }
  
  .leftText{
    font-size: 25px;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #0a0a0a;
  }
  
  .bysubmit{
    font-size: 12px;
    text-align: center;
  
  }
 
  .titleDet {
    font-size: 15px;
    font-family:'Times New Roman', Times, serif;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .titleDate{
    font-size: 20px;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: #f35309;
   
  }
  .infos{
  text-align: center;
  align-content: center;
  
  }
  .int{
    font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
    color: #faf9f9;
  
  }
  button{
    align-self: center;
    margin-bottom: 1rem;
  }
  
  .formComment {
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
  }
  
  .formComment h2 {
    margin-bottom: 5px;
  }
  
  .formComment p {
    margin: 0;
  }
  textarea{
    height: 5rem;
  }
  
  .formDetail h2 {
    margin-bottom: 20px;
  }
  
  
  
  .formDetail label {
    margin-bottom: 15px;
    font-size: 10px;
    color: #5a5463;
   
  }
  
  
  
  
  .formDetail input,
  .formDetail textarea, select {
    width: 90%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #5a5463;
  }
  
  .formDetail button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #f35d07;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    margin-top: 10px;
    width: 50%;
  }
  
  .formDetail button:hover {
    background-color: #ee6f06;
  }

}
.mainHero {
  display: flex;
  height: auto; /* Adjust height as needed */
  padding-bottom: 1rem;



}
.imageHero{
  max-width: 100%;
  height: Auto;
}

.hero-left {
  width: 40%;
  /* Assuming you want to use a background image for hero-left */
  display: flex;
justify-content: center;
align-items: center;
background-color: #ffffff;
}
  

  .hero-right {
  
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  


.imagess {
  max-width: 100%; /* Ensure the image fills its container */
  height: auto; /* Maintain aspect ratio */
  max-height: 100%;
  
}







@media (max-width: 768px) {
  

    .mainHero {
      flex-direction: column;
    
  }


  .hero-left {
    width: 100%;
    /* Assuming you want to use a background image for hero-left */
    display: flex;

  }
    
  
    .hero-right {
    
      width: 100%;
      display: flex;
     
    }
    
}


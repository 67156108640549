/* Call to Action Section Styles */
.call-to-action {
    background-color:#806b7b;
    color: #fdfcfc;
   display: flex;
   align-items: center;
   justify-content: center;
    text-align: center;
    height: 300px;
    
  }
  
  .cta-headline {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
  }
  
  .cta-subheadline {
    font-size: 1.525rem;
    margin-bottom: 1rem;

  }
  
  .cta-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1rem;
    margin: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .cta-button.primary {
    background-color: #0398ee;
    color: #ffffff;
  }
  
  .cta-button.primary:hover {
    background-color: #fc6806;
  }
  
  .cta-button.secondary {
    background-color: transparent;
    color: #f6f6f7;
    border: 2px solid #fcfdff;
    background-color: #4d3647;
  }
  
  .cta-button.secondary:hover {
    background-color: #00bcd4;
    color: #ffffff;
  }








  @media(max-width:765px){


    .call-to-action {
      background-color:#ffffff;
      color: #000000;
     display: flex;
     align-items: center;
     justify-content: center;
      text-align: center;
      height: 180px;
      
    }
    


.cta-headline {
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

}

.cta-subheadline {
  font-size: 1rem;
  margin-bottom: 1rem;

}

.cta-button {
  padding: 0.2rem 0.7rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: .7rem;
  margin: 0.5rem;
  transition: background-color 0.3s ease;
}

.cta-button.primary {
  background-color: #0398ee;
  color: #ffffff;
}

.cta-button.primary:hover {
  background-color: #fc6806;
}

.cta-button.secondary {
  background-color: transparent;
  color: #0a61e4;
  border: 2px solid #1f64e4;
}

.cta-button.secondary:hover {
  background-color: #00bcd4;
  color: #ffffff;
}



}
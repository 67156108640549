/* Testimonials Section Styles */

  

  
.welcom {
  display: flex;
  height: 50vh; /* Adjust height as needed */
  padding-top: 3rem;
  background-color:#1a0020;
  padding-bottom: 2rem;
 

}

/*.welcom-image {
  flex: 1;
 background-color: #4bc413;
 width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  overflow: hidden; /* Ensure no overflow 

}
.welcoming{
max-width: 100%;
height: auto;
display: block;
display: block; /* Remove any gaps below the image 
overflow: hidden;  Ensure no overflow 


}*/
.welcom-image {
  display: flex;
 
  flex: 1;
  height: auto; /* Ensure the height adjusts according to the width */
  /*overflow: hidden; /* Ensure no overflow */
}

.welcoming {
  width: 100%;
  height: auto; 
  transition: opacity 10s ease-in-out; /* Transition time set to 0.2 seconds */
  opacity: 5;/* Maintain aspect ratio */
  /*display: block; /* Remove any gaps below the image */

}



.welcom-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem;
  background-color: #140122;
 

}

.welcom-headline {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #fd6f11;
  max-width: 100%;
  height: auto;
}

.welcom-subheadline {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #bdb0a8;
  max-width: 100%
  ;
}

.cta-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  background-color: #ff7300;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #b30095;
}



/* media phone*/


/* Media query for screens up to 768px */
@media (max-width: 768px) {

    .welcom {
      flex-direction: column; /* Stack items vertically */
      height: auto; /* Adjust height as needed */
      padding-top: 3vh;
    }
  
    .welcom-content {
      order: 2; /* Display content first */
      padding: 0.5rem; /* Adjust padding for smaller screens */
    }
  
    .welcom-image {
      order: 1; /* Display image second */
    }
  
    .welcom-headline {
      font-size: 2rem; /* Decrease font size for smaller screens */
    }
  
    .welcom-subheadline {
      font-size: 1.2rem; /* Decrease font size for smaller screens */
    }
  
    .cta-button {
      font-size: 0.9rem; /* Decrease font size for smaller screens */
      padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
    }
  }

.programs{
  align-items: center;
  text-align: center;
  background-color:#1a0020;
  padding-bottom: 3rem;
}

.progContainer{
display: flex;
gap: .5rem;
margin-right: 1.5rem;
flex-direction: column;
align-content: space-between;
align-items: center;


}
.imLeft{
  
  width: 250px;
  height: auto;
  object-fit: cover;

  
  margin-bottom: 5px;
}
.instName{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.3rem;

  gap: 1rem;
  color: rgb(255, 255, 255);
}

.progDetail{
  
  font-size: 14px;
  text-align:center;
  
}

.programs-headline
{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
 margin-top: 3rem;
  color: #fa5f06;

}
.portal{
  color: #fa5f06;
  font-size: 15px;
 
}

.program{
background-color: rgb(253, 244, 228);
width:20vw;
border-radius: 10px;
padding: 10px;
min-width: 220px;
}
.program0{
  background-color: rgb(247, 222, 190);
  width:20vw;
  border-radius: 10px;
  padding: 10px;
  min-width: 220px;
  
  }


.program1{
  background-color: rgb(253, 210, 158);
  width:20vw;
  border-radius: 10px;
  padding: 10px;
  min-width: 220px;
  
  }


.program2{
  background-color: rgb(255, 179, 86);
  width:20vw;
  border-radius: 10px;
  padding: 10px;
  min-width: 220px;
  
  }

.progRight{
display: flex;
gap: 1.5rem;
width: 90%;
justify-content: space-between;


}

.btncourse{
  background-color: transparent;
  color: #f1f2f3;
  background-color: #ee5b05;
padding: 10px;
  border-radius: 7PX;

}

.progLeft {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 3rem;
  color: #f1f2f3;
  gap: 4rem;
  justify-content: center;
 

}

.inst {
  flex: 1 1; /* Flex-grow: 1, flex-shrink: 1, flex-basis: calc(50% - 1rem) */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(253, 250, 249, 0.267);
  background-color: #000000;
  padding: 2px;
  border-radius: 10px;



    
}

.instrTitle{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
   color: rgb(188, 189, 190);
}

.progText{
color: #f1f2f3;

}



/* PHONE */
@media(max-width:768px){


  .programs{
    align-items: center;
    text-align: center;
    background-color:#1a0020;
   display: flex;
   flex-direction: column;
   align-items: center;
  }



  .instName{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1rem;
  
  
    color: rgb(255, 255, 255);
  }
  




  .progText{
font-size: 15px;
margin-top: 0rem;
padding: 15px;
  }

  .progContainer{
  display: flex;
  flex-direction: column;
  }
  .progLeft {
    justify-content: center;
    align-items: center;
    width: 95%; /* Ensure the container takes full width */
   
  }


  .inst {
  
    margin: .5rem;
      display: flex;
    flex-direction: column;
    align-items: center;
  

  }

  .imLeft {
   width: 30vw;
   height: auto;

  }
 
  .progRight{
    display: flex;
   
    width: 100vw;

    flex-direction: column;
  
    justify-content: center;
    align-items: center;
  
  
  }

    .program{
      background-color: rgb(253, 244, 228);
      width:80vw;
      border-radius: 10px;
      padding: 10px;
      }
      .program0{
        background-color: rgb(247, 222, 190);
        width:80vw;
    border-radius: 10px;
    padding: 10px;
        
        }
      
      
      .program1{
        background-color: rgb(253, 210, 158);
        width:80vw;
        border-radius: 10px;
        padding: 10px;
        }
      
      
      .program2{
        background-color: rgb(255, 179, 86);
        width:80vw;
    border-radius: 10px;
    padding: 10px;
        }
  
  .progDetail{
    
    font-size: 14px;
    text-align:center;
    
  }
  

  .portal{
    color: #fa5f06;
    font-size: 15px;
   
  }
  .btncourse{
    background-color: transparent;
    color: #f1f2f3;
    background-color: #ee5b05;
  padding: 10px;
    border-radius: 7PX;
 
  }
 
  .progLeft {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 1rem;
    color: #f1f2f3;
    justify-content: center;
    gap: 0rem;
   
  
  }

  .instrTitle{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.6rem;
     color: rgb(186, 188, 189);
  }
  






}
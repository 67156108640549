
.footer {
  background-color:#1a0020;
    color: #fff;
    padding: 2rem 0;
    text-align: center;
  }
  
  .footer-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .footer-nav a {
    color: #00bcd4;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }


    /* Header Styles */
header {
  background-color:#1a0020;
  
    padding: 1rem 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 12px;
    height: 65px;

  }
  
  .containerheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo{
    width: 250px;
    height: 60px;
    margin-left: 50px;
    box-shadow: 1px 1px 5px 1px rgb(255, 254, 254); /* Add shadow to logo */
    border-radius: 10px;

   
    
 }
 
  
.apply {
 
  margin: .8rem;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    color: rgb(247, 250, 250);
    background-color: #f57803;

  }

  


  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    margin-left: 2rem;
  }
  
  nav ul li a {
    color: #f0ece6;
    text-decoration: none;
    font-size: .9rem;
  }
  
  nav ul li a:hover {
    color: #e48b17;
  }

  @media(max-width:768px) {
    header{
      height:60px;
    }

     .logo{
        width: 90px;
        height: 25px;
        margin-left: 10px;
        box-shadow: 1px 1px 5px 1px rgb(253, 252, 251); /* Add shadow to logo */
        border-radius: 5px;
       
      }
      nav ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      nav ul li {
        margin-left: .8rem;
      }
      nav ul li a {
        color: #f0ece6;
        text-decoration: none;
        font-size: 10px;
      }
      nav ul li a:hover {
        color: #e48b17;
      }

   .apply {
 
        margin: .3rem;
          padding: 0.3rem .5rem;
          border-radius: 4px;
          font-size: 10px;
         
        }
 


header{
  flex-direction: column-reverse; /* Reverse the order on smaller screens */
  height: 50px; /* Auto height to fit content */

}
  }
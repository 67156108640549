/* FAQ Section Styles */
.faq {
  background-color:#1a0020;
    color: #fff;
    padding: 4rem 0;
  }
  
  .faq-headline {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-accordion {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-bottom: 1rem;
  }
  
  .faq-question {
    background-color: #2a2a2a;
    color: #fff;
    padding: 1rem;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #333;
  }
  
  .faq-answer {
    background-color: #2a2a2a;
    padding: 1rem;
    display: none;
  }
  
  .faq-answer p {
    margin: 0;
  }